.App {
    text-align: center;
    margin: 1rem auto;
    padding: 1rem 0 2rem;
    max-width: 50rem;
    background-color: white;
}

.App-link {
    color: #61dafb;
}