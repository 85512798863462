.textAlignLeft {
    text-align: left;
}

.textAlignRight {
    text-align: right;
}

.topMargin {
    margin-top: 18px;
}

.bottomMargin {
    margin-bottom: 28px;
}

.meterLocation {
    font-size: x-large;
}

.textMargin {
    margin: 30px;
}

.gaugeHeader {
    background:#3F88C5;
    width:100%;
    float: left;
    font-size: x-large;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.marginLeftRight {
    margin-left: 15px;
    margin-right: 15px;
}

.poweredBySection {
    font-size: small;
    font-style: italic;
}