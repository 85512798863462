.fancyBorder {
    width: 100%;
    text-align: center;
    border-top: 5px solid;
    border-image: linear-gradient(to right, #67f948 0%, #9deb56 20%, #efff42 45%, #fde143 65%, #ff4242 80%, #960032 90%) 5;
}


.main {
    display: table;

    /* optional css start */
    height: 100%;
    width: 100%;
    /* optional css end */
}

.verticalCenter {
    padding-top: 10px;
    display: table-cell;
    vertical-align: middle;
}

.colorTomorrow {

}

.colorDayAfterTomorrow {

}

.barText {
    font-size: small;
}