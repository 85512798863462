body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #3f88c5;
    padding: 1rem 0 2rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Main widget wrapper */

.c-intro,
.c-outro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    max-width: 50rem;
    margin: 0 auto;
}

.c-intro h1 {
    font-size: 1.75rem;
    font-weight: bold;
}

.c-intro p {
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
}

.c-outro p {
    font-weight: bold;
    font-size: 1.125rem;
}

.c-outro__more-info {
    display: block;
    margin: 3rem 0 0;
    font-size: 1rem;
    font-weight: bold;
}

.c-outro__qr-code {
    margin: 1rem 0 0;
    width: 10rem;
}

/* Special styles for widget generated classes */

/* Air Quality Index headline */
.styles_gaugeHeader__ECsqH {
    font-weight: bold;
}

/* Forecast bars */
.styles_barText__ONGvw {
    padding: 1rem 0;
}

/* Description text for forecasts */
.styles_verticalBottom__gz11u {
    padding: 0 1rem;
}

